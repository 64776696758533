
import { defineComponent, ref, onMounted } from "vue";
import {getPromotions} from "@/core/data/StorePromotion"
interface Filter {
  idPromotion?: number;
  idVoucher?: number;
}

export default defineComponent({
  name: "store-filter",
  components: {},
  setup(props,{emit}) {
    const data = ref<Filter>({
    });
    const dataPromotions = ref()
    onMounted(() => {
      getPromotions(0, 9999, false, '')
          .then(response => {
            dataPromotions.value = response.data.data
            console.log(dataPromotions.value)
          })
    })
    const handleReset = () => {
      data.value = {}
      emit('handle-search', data.value)
    }

    return {
      data,
      handleReset,
      dataPromotions
    };
  },
});
