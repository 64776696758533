import ApiService from "@/core/services/ApiService";
type Nullable<T> = T | undefined | null;
interface IStore {
  promotionVoucherId?: Nullable<number>;
  storeId?: Nullable<number>;
  storeName: Nullable<Nullable<string>>;
  packageId: Nullable<number>;
  packageName: Nullable<Nullable<string>>;
  numberMonth: Nullable<number>;
  timeOfUse: Nullable<number>;
  price: Nullable<number>;
  discount: Nullable<number>;
  promotionCode: Nullable<string>;
  voucher: {
    voucherId: Nullable<number>;
    code: Nullable<number>;
    discountPrice: Nullable<number>;
    discountRate: Nullable<number>;
    totalDiscount: Nullable<number>;
  };
  promotionId?: Nullable<number>;
  parentPromotionId?: Nullable<number>;
}

function getPromotions(
  page = 0,
  size = 10,
  isMain = false,
  name = "",
  package_id = null,
  state = null
) {
  const params = {
    isMain: isMain,
    name: name,
    package_id: package_id,
    state: state,
    page: page,
    size: size,
  };
  ApiService.setHeader();
  return ApiService.query("/v1/package/promotion", { params });
}

function getStores(page = 0,
                   size = 10,promotionId = null, promotionCode = null) {
  ApiService.setHeader();
  const params: any = {};
  if (promotionId != '') params.promotionId = promotionId
  if (promotionCode != '') params.promotionCode = promotionCode
  return ApiService.query(`/v1/package/promotion/history`, {params});
}


export {
  IStore,
  getStores,
};
