
import ExportModal from "@/components/modals/forms/ExportModal.vue";
import { excelParser } from "@/core/helpers/excel-parser";
import { defineComponent, onMounted, ref } from "vue";
import StoreFilter from '@/views/apps/marketings/store/components/StoreFilter.vue';
import {IStore, getStores} from  '@/core/data/Store'
import {getPromotions} from "@/core/data/StorePromotion";
import moment from "moment";
export default defineComponent({
  name: "store-promotion-vouchers",
  components:{
    StoreFilter,
    ExportModal
  },
  setup(){
    const promotionId = ref()
    const voucherId = ref()
    const convertIntToDateTime = (value) => {
      let timestamp = value / 1000;
      return moment.unix(timestamp).format("DD-MM-YYYY HH:mm:ss");
    }
    const exportData = () => {
      getStores(0, 9999, promotionId.value, voucherId.value)
          .then((response) => {
            const data = response.data.data
            let listFiltered: any = []
            for(let i = 0 ; i < data.length; i++){
              let item = data[i]
              let newItem = {
                'Id gian hàng': item.storeId,
                'Tên gian hàng': item.storeName,
                'Gói dịch vụ': item.packageName + ' ' + item.numberMonth + ' tháng',
                'Mã voucher': item.voucher.code,
                'Mức ưu đãi(%)': item.voucher.discountRate,
                'Thời gian kích hoạt voucher': convertIntToDateTime(item.timeOfUse),
              }
              listFiltered.push(newItem)
            }
            let currentDate = moment().format("DD_MM_YYYY");
            let fileName = currentDate + '_danhsachgianhangnhanuudai'
            excelParser().exportDataFromJSON(listFiltered, fileName, null);
          })
    }
    const baseOldCmsUrl = ref(process.env.VUE_APP_OLD_CMS_URL);
    const metadata = ref();
    const dataStore = ref<[IStore]>()
    const handleSearch = (data) => {
      promotionId.value = data.idPromotion
      voucherId.value = data.idVoucher
      renderList(promotionId.value, voucherId.value)
    }

    const renderList = (promotionId = null, voucherId = null) => {
      getStores(0, 9999, promotionId, voucherId)
          .then((response) => {
            dataStore.value = response.data.data
            metadata.value = response.data.metadata;
            // if (tableData.value.length > 0) {
            //   initStorePromotion.value.splice(
            //       0,
            //       tableData.value.length,
            //       ...tableData.value
            //   );
            // }
          })
    };
    onMounted(() => {
      renderList()
    })

    return {
      handleSearch,
      dataStore,
      metadata,
      baseOldCmsUrl,
      exportData
    }
  },
  methods:{
    convertIntToDateTime(value) {
      let timestamp = value / 1000;
      return moment.unix(timestamp).format("DD-MM-YYYY HH:mm:ss");
    },
  }
})
